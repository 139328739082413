import React from "react";
import "./Supporters.css";
import devfolioLogo from "../img/sponsors/devfolio.png";
import wolframLogo from "../img/sponsors/Wolfram.png";
import ethIndiaLogo from "../img/sponsors/ethindia-light.png";
import polygonLogo from "../img/sponsors/polygon-light.png";
import nordsec from "../img/sponsors/nordsec.png"
import axureLogo from "../img/sponsors/axurelogo.png";
import rosenfeldLogo from "../img/sponsors/rosenfeld.png";
import balsamiqLogo from "../img/sponsors/balsamiq.png";
import interviewCakeLogo from "../img/sponsors/Interview Cake.png";
import echo3dLogo from "../img/sponsors/echo3dhackbout3.png";
import AoPSLogo from "../img/sponsors/AoPS.png";

const Supporters = () => {
  return (
    <section className="supporters-section" id="supporters">
      <div className="support-block">
        
        <div className="support-heading">Sponsors</div>
        <div className="support-content">
          <div className="support-tier">
            <h2>Platinum</h2>
            <div className="plat-supporters">
              <a href="https://devfolio.co" target="_blank" rel="noopener noreferrer">
                <div className="devfolio-logo"><img src={devfolioLogo} alt="DEVFOLIO LOGO"/></div>
              </a>
              <a href="https://www.wolfram.com" target="_blank" rel="noopener noreferrer">
                <img src={wolframLogo} alt="WOLFRAM" />
              </a>
            </div>
          </div>
          <div className="support-tier">
            <h2>Gold</h2>
            <div className="gold-supporters">
              <a href="https://ethindia.co" target="_blank" rel="noopener noreferrer">
                <img src={ethIndiaLogo} alt="ETHINDIA LOGO" />
              </a>
              <a href="https://polygon.technology" target="_blank" rel="noopener noreferrer">
                <img src={polygonLogo} alt="POLYGON LOGO" />
              </a>
              <a href="https://nordsecurity.com" target="_blank" rel="noopener noreferrer">
                <img src={nordsec} alt="Nord Security" />
              </a>
              <a href="https://www.axure.com" target="_blank" rel="noopener noreferrer">
                <img src={axureLogo} alt="Axure" />
              </a>
            </div>
          </div>
          <div className="support-tier">
            <h2>Silver</h2>
            <div className="silver-supporters">
              <a href="https://rosenfeldmedia.com" target="_blank" rel="noopener noreferrer">
                <img src={rosenfeldLogo} alt="Rosenfeld Logo" />
              </a>
              <a href="https://www.echo3d.com" target="_blank" rel="noopener noreferrer">
                <img src={echo3dLogo} alt="Echo 3d" />
              </a>
              <a href="https://artofproblemsolving.com" target="_blank" rel="noopener noreferrer">
                <img src={AoPSLogo} alt="AoPS" />
              </a>
              <a href="https://balsamiq.com" target="_blank" rel="noopener noreferrer">
                <img src={balsamiqLogo} alt="Balsamiq" />
              </a>
              <a href="https://www.interviewcake.com" target="_blank" rel="noopener noreferrer">
                <img src={interviewCakeLogo} alt="Interview Cake" />
              </a>
            </div>
          </div>
        </div>
        <div className="glow-top-left background-glow-element"></div>
        <div className="glow-top-right background-glow-element"></div>
        <div className="glow-right background-glow-element"></div>
        <div className="right-looper background-glow-element"></div>
        <div className="bg-loop-design background-glow-element"></div>
      </div>
    </section>
  );
};

export default Supporters;