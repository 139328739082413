//imports
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

//layouts
import RootLayout from "./layouts/RootLayout";
// import SponsorLayout from "./layouts/SponsorLayout";

//pages
import NotFound from "./pages/NotFound";

//css
import "./components/fonts.css";
import "./App.css";

const App = () => {
  const router = createBrowserRouter (
    createRoutesFromElements(
      <>
      <Route index element ={<RootLayout/>} />
      <Route path="*" element={<NotFound />} />
      </>
    )
  )
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
