import React, { useEffect } from 'react';
import './Navbar.css';

const NavbarComponent = () => {
  // Function to handle scrolling to the corresponding section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleHamburgerClick = () => {
      document.querySelector('.navbar').classList.toggle('active');
    };

    const hamburgerBtn = document.getElementById('hamburger-btn');
    if (hamburgerBtn) {
      hamburgerBtn.addEventListener('click', handleHamburgerClick);
    }

    return () => {
      if (hamburgerBtn) {
        hamburgerBtn.removeEventListener('click', handleHamburgerClick);
      }
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="logo-holder">
        <div className="qbit-logo"></div>
      </div>
      <div className="link-holder">
        <div className="navbar-links">
          <button className="link-btn" onClick={() => scrollToSection('hero')}>Home</button>
          <button className="link-btn" onClick={() => scrollToSection('about-us')}>About Us</button>
          <button className="link-btn" onClick={() => scrollToSection('tracks')}>Tracks</button>
          <button className="link-btn" onClick={() => scrollToSection('prizes')}>Prizes</button>
          <button className="link-btn" onClick={() => scrollToSection('timeline')}>Timeline</button>
          <button className="link-btn" onClick={() => scrollToSection('supporters')}>Sponsors</button>
          <button className="link-btn" onClick={() => scrollToSection('contact')}>Contact us</button>
        </div>
      </div>
      <button className="hamburger-btn" id="hamburger-btn">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
    </nav>
  );
};

export default NavbarComponent;
