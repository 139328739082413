import React from 'react';
import './Timeline.css';
import timelineEventsImage from '../img/timeline/timeline1.svg';

const Timeline = () => {
  return (
    <section className="timeline-container" id="timeline">
      <div className="timeline-heading">Timeline</div>
      <div className="timeline-content">
        <img src={timelineEventsImage} alt="Timeline Events" className="timeline-events-image" />
      </div>
      <div className="left-timeline-design"></div>
      <div className="right-timeline-design"></div>
    </section>
  );
};

export default Timeline;
