import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  const instagramLink = 'https://www.instagram.com/qbits_nmit';
  const linkedinLink = 'https://www.linkedin.com/company/qbitsnmit/';
  const emailLink = 'mailto:qbits@nmit.ac.in';
  const discordLink = 'https://www.discord.com/invite/pJB5zKGPZt'
  
  return (
    <section className="contact" id="contact">
      <div className="contact-heading">
        Contact Us
      </div>
      <div className="contact-buttons">
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <img src={require('../img/contact/instagram.png')} alt="Instagram" />
        </a>
        <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
          <img src={require('../img/contact/linkedin.png')} alt="Linkedin" />
        </a>
        <a href={emailLink} target="_blank" rel="noopener noreferrer">
          <img src={require('../img/contact/mail.png')} alt="Email" />
        </a>
        <a href={discordLink} target="_blank" rel="noopener noreferrer">
          <img src={require('../img/contact/discord.png')} alt="Email" />
        </a>
      </div>
    </section>
  );
};

export default ContactUs;
