import React from 'react'
import HeroWithNav from '../components/HeroWithNav';
import AboutUs from '../components/AboutUs';
import Tracks from '../components/Tracks';
import Prizes from '../components/Prizes';
import Supporters from '../components/Supporters';
import Timeline from '../components/Timeline';
import ContactUs from '../components/ContactUs';
export default function RootLayout() {
    return (
        <div className="App">
            <div class="hero-block">
                <HeroWithNav />
            </div>
            <div className="about-block">
                <AboutUs />
            </div>
            <div className="tracks-block">
                <Tracks />
            </div>
            <div className="prizes-block">
                <Prizes />
            </div>
            <div className="timline-block">
                <Timeline />
            </div>
            <div className="supporters-block">
                <Supporters />
            </div>
            <div className="contact-block">
                <ContactUs />
            </div>
        </div>

    )
}
