// Hero.js
import React, { useEffect } from 'react';
import './Hero.css';
import mainLogo from '../img/home/logo.svg';

const Hero = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apply.devfolio.co/v2/sdk.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="hero">
      <div className="hero-heading">
        <img src={mainLogo} alt="Quantamaze Logo"></img>
        <h2>36-Hour Hackathon</h2>
      </div>
      <div className="apply-button-container" style={{ height: '44px', width: '312px', marginTop: '1rem' }}>
        <div 
          className="apply-button" 
          data-hackathon-slug="quantamaze-2" 
          data-button-theme="dark-inverted"
          style={{ height: '44px', width: '312px' }}
        ></div>
      </div>


      <div className="hero-subheading">
        <div className="event-date">
        </div>
        <div className="event-venue">
          Nitte Meenakshi Institute of Technology, Bangalore
        </div>
      </div>
    </section>
  );
};

export default Hero;
